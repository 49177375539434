import React, { Component } from "react";
import Stserviceitems from "./StserviceItem";
import Reveal from "react-reveal/Reveal";
class Sservice extends Component {
  render() {
    return (
      <section className="agency_service_area bg_color">
        <div className="container">
          <h2
            className="f_size_30 f_600 t_color3 l_height40 text-center mb_90 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            Renewable Energy Alliance Approach
          </h2>
          <div className="row mb_30">
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1100}>
                <Stserviceitems
                  iShap="icon_shape1.png"
                  Sicon="ti-panel"
                  description="To educate and advocate renewable energy use by encouraging and empowering companies and individuals to transition to clean energy.
                  "
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1400}>
                <Stserviceitems
                  iShap="icon_shape2.png"
                  Sicon="ti-layout-grid2"
                  description="To set a global standard by establishing specifications and certifications for the creation of green energy.
                  "
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
                <Stserviceitems
                  iShap="icon_shape3.png"
                  Sicon="ti-gallery"
                  description="Author incentivization programs that reward companies and individuals for creating renewable energy and implementing those solutions.
                  "
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
                <Stserviceitems
                  iShap="icon_shape1.png"
                  Sicon="ti-gallery"
                  description="Advocate sustainable development - adopt the best technologies from the most innovative companies and organizations that are solving the problems 
                  "
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
                <Stserviceitems
                  iShap="icon_shape2.png"
                  Sicon="ti-gallery"
                  description="Change the relationship with the planet to be symbiotic and regenerative.
                  "
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
                <Stserviceitems
                  iShap="icon_shape3.png"
                  Sicon="ti-gallery"
                  description="Building a community and inviting the most brilliant minds in the space to come together through annual events and podcasts.
                  "
                />
              </Reveal>
            </div>
          </div>
        </div>

        <div className="container">
          <h2
            className="f_size_30 f_600 t_color3 l_height40 text-center mb_90 wow fadeInUp"
            data-wow-delay="0.2s"
          >
            INCENTIVIZATION PROGRAMS
          </h2>
          <div className="row mb_30">
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1100}>
                <Stserviceitems
                  iShap="icon_shape1.png"
                  Sicon="ti-panel"
                  description="Create a regenerative economic system."
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1400}>
                <Stserviceitems
                  iShap="icon_shape2.png"
                  Sicon="ti-layout-grid2"
                  description="We aim to financially reward companies and individuals for creating renewable energy and implementing those solutions."
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
                <Stserviceitems
                  iShap="icon_shape3.png"
                  Sicon="ti-gallery"
                  description="We deliver integrated incentivized green solutions, complementing our capabilities with a curated ecosystem of the world’s leading innovators."
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
                <Stserviceitems
                  iShap="icon_shape1.png"
                  Sicon="ti-gallery"
                  description="Deep ties with educations and governments to integrate a global reward system."
                />
              </Reveal>
            </div>
            <div className="col-lg-4 col-sm-6">
              <Reveal effect="fadeInUp" duration={1700}>
                <Stserviceitems
                  iShap="icon_shape2.png"
                  Sicon="ti-gallery"
                  description="REC’s and Carbon Credits"
                />
              </Reveal>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Sservice;
