import React, {Component} from 'react';

class FooterThree extends Component{
    render(){
        return(
            <footer className="footer_nine_area">
                <div className="footer_nine_bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-7 align-self-center">
                                <p className="mb-0 f_400 text-white">Copyright © 2018 Design by <a href=".#">WebDBTech</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}
export default FooterThree;