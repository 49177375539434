import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Sticky from 'react-stickynode';

class CustomNavbar extends Component {
    render() {
        var {mClass, nClass, cClass, slogo} =this.props;
        return (
            <Sticky top={0} innerZ={9999} activeClass="navbar_fixed">
                <header className="header_area">
                <nav className={`navbar navbar-expand-lg menu_one ${mClass}`}>
                    <div className={`container ${cClass}`}>
                        <Link className={`navbar-brand ${slogo}`} to="/">
                            <img src={require("../img/logo2.png")} alt=""/>
                            <img src={require("../img/logo.png")} alt="logo"/>
                        </Link>
                        <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="menu_toggle">
                                <span className="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </span>
                                <span className="hamburger-cross">
                                    <span></span>
                                    <span></span>
                                </span>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className={`navbar-nav menu  ${nClass}`}>
                            <li className="nav-item">
                                    <Link to="/About" className="nav-link"  >
                                        About Us
                                    </Link>
                               </li>
                                
                                <li className="dropdown submenu nav-item">
                                    <Link to="/Certification" className="nav-link"  >
                                    Certification
                                    </Link>
                                </li>
                                <li className="dropdown submenu nav-item">
                                    <Link to="/Partners" className="nav-link"  >
                                    Partners
                                    </Link>
                                </li>
                                <li className="nav-item dropdown submenu">
                                    <Link to="/Sustainability" className="nav-link"  >
                                    Sustainability
                                    </Link>
                                </li>
                                {/* <li className="nav-item"><NavLink title="Pricing" className="nav-link" to="/Contact">Contact</NavLink></li> */}
                                <li className="nav-item"><a className="nav-link g_color" href="#get-app">Go Green Now</a></li>  
                            </ul>
                        </div>
                    </div>
                </nav>
                </header>
            </Sticky>
        );
    }
}

export default CustomNavbar;