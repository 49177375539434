import React, {Component} from 'react';
import Reveal from 'react-reveal/Reveal'

class PaymentBanner extends Component {
    render(){
        return(
            <section className="payment_banner_area">
                <div className="container pt-5 mt-5">
                   <Reveal effect="fadeInLeft" duration={500}>
                        <div className="payment_banner_content wow fadeInLeft" data-wow-delay="0.4s">
                            <p className="g_color f_p f_size_30"> A completely</p>
                            <p className="g_color f_p f_size_30">Renewable Energy Future </p>
                            <p className="g_color f_p f_size_30">To Regenerate the world.</p>
                            <p className="w_color f_p f_size_18"> An alliance to ensure sustainable renewable energy</p>
                            <div className="action_btn d-flex align-items-center mt_60">
                                <a href=".#" className="btn_hover agency_banner_btn">Sign Up</a>
                                <a href=".#" className="agency_banner_btn_two">Find Out More<i className="ti-arrow-right"></i></a>
                            </div>
                        </div>
                   </Reveal>
                </div>
            </section>
        )
    }
}
export default PaymentBanner;