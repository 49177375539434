import React, { Component } from "react";
import Reveal from "react-reveal/Reveal";

class PaymentFeatures extends Component {
  render() {
    return (
      <section className="payment_features_area">
        <div className="bg_shape shape_one"></div>
        <div className="bg_shape shape_two"></div>
        <div className="bg_shape shape_three"></div>
        <div className="container">
          <div className="row featured_item mt-4">
            <div className="col-lg-7 d-flex align-items-center">
              <Reveal effect="fadeInRight" duration={800}>
                <div className="payment_features_content pl_70">
                  {/* <div className="icon">
                                        <img className="img_shape" src={require('../../img/home9/icon_shape.png')} alt=""/>
                                        <img className="icon_img" src={require('../../img/home9/icon2.png')} alt=""/>
                                    </div> */}
                  <h2>Current Problem</h2>
                  <ol>
                    <li className="mb-2">
                      <b>Climate change: </b>the concentration of carbon dioxide
                      (CO2)​​​​​​​ in our atmosphere is 416 part​s per million
                      which is the highest it has been in human history. This
                      has helped to make 2020 the 2nd hottest year on record.
                    </li>
                    <li className="mb-2">
                      <b>Technology: </b>mass adaptation of renewable energy
                      technologies are lagging to meet the goal of net-zero
                      global greenhouse gas emissions by 2050.
                    </li>
                    <li className="mb-2">
                      <b>The planet: </b>the unsustainable use of land, soil,
                      water and energy for food contributes to greenhouse
                      emissions that cause rising temperatures. Higher
                      temperatures, in turn, affect resources to produce food
                      which creates more poverty. Up to 811 million people in
                      the world faced hunger in 2020, 161 million more than in
                      2019.
                    </li>
                    <li>
                      <b>Poverty: </b>1.1 billion people don’t have access to
                      reliable energy which denies them basic opportunities such
                      as running a business and providing light for their
                      children to study.
                    </li>
                  </ol>
                </div>
              </Reveal>
            </div>
            <div className="col-lg-5 justify-content-center d-flex">
              <Reveal effect="fadeInLeft">
                <div>
                  <img
                    src={require("../../img/home9/featured_img.png")}
                    alt=""
                    className="img img-fluid"
                  />
                </div>
              </Reveal>
            </div>
          </div>
          <div className="row flex-row-reverse featured_item">
            <div className="col-lg-7 d-flex align-items-center">
              <Reveal effect="fadeInLeft" duration={1200}>
                <div className="payment_features_content pr_70">
                  {/* <div className="icon">
                    <img
                      className="img_shape"
                      src={require("../../img/home9/icon_shape.png")}
                      alt=""
                    />
                    <img
                      className="icon_img"
                      src={require("../../img/home9/icon2.png")}
                      alt=""
                    />
                  </div> */}
                  <h2>Opportunity </h2>
                  <ol>
                    <li className="mb-2">
                      A global movement of companies, organizations and
                      individuals committed to providing solutions to the
                      world’s energy and environmental problems is desperately
                      needed.
                    </li>
                    <li className="mb-2">
                      A transition to renewable energy production can help solve
                      the persistent global problems facing humanity.
                    </li>
                    <li className="mb-2">
                      Shifting to a green economy could yield a direct economic
                      gain of $26 trillion through 2030 compared with business
                      as usual. This could produce over 65 million new
                      low-carbon jobs.
                    </li>
                    <li className="mb-2">
                      A green transition, including a shift to renewable energy
                      such as the manufacturing of electric vehicles and
                      construction of energy-efficient buildings, will create 24
                      million jobs by 2030, far more than the 6 million that
                      could be lost.
                    </li>
                    <li className="mb-2">
                      The combination of generating renewable energy and
                      providing a financial system will help release many who
                      are trapped in poverty.
                    </li>
                    <li className="mb-2">
                      Create an energy star standard for renewable products.
                    </li>
                  </ol>
                  {/* <a href=".#" className="btn_hover agency_banner_btn pay_btn">
                    Pay On Ebay
                  </a>
                  <a
                    href=".#"
                    className="btn_hover agency_banner_btn pay_btn pay_btn_two"
                  >
                    Pay On Website
                  </a> */}
                </div>
              </Reveal>
            </div>
            <div className="col-lg-5">
              <Reveal effect="fadeInRight" duration={800}>
                <div>
                  <img
                    src={require("../../img/home9/featured_img_two.png")}
                    alt=""
                    className="img img-fluid"
                  />
                </div>
              </Reveal>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default PaymentFeatures;
